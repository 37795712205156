
const getRegexPattern = async (code) => {
  let results = [];
  await fetch(process.env.REACT_APP_URL + "data/" + code + "/regex.json")
    .then(response => response.json())
    .then(data => results = data)
    .catch(error => {
      console.error('Error fetching JSON:', error);
    });
  return results;
}

export { getRegexPattern };