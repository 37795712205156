import React, { useState } from "react";
import { Fragment } from "react";
import "./header.scss";
import { NavLink } from "react-router-dom";

export const Header = () => {
    const [opened, setOpened] = useState(false);


    return <Fragment>
        <div className="header-line"></div>
        <div className="header">
            <div className="content-block">
                <NavLink to="/">
                    <img src="../logo.png" className="logo" alt="thienkiep" />
                </NavLink>
                <div className="menu">
                    <i className="icon-menu simple-icon-menu" onClick={() => setOpened(!opened)} />
                    <div className={opened ? "main-menu opened" : "main-menu"} onClick={() => setOpened(false)}>
                        <NavLink to="/" className="header-item">
                            Home
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}