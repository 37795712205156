import React from 'react';

export const Form = ({ id, labelMode, formData, readOnly, showColonAfterLabel, labelLocation, minColWidth, colCount, onChange }) => {
    const handleChange = (key, value) => {
        if (onChange) {
            onChange({ ...formData, [key]: value });
        }
    };

    return (
        <form id={id} className="form">
            {/* Render form fields based on formData */}
            {Object.keys(formData).map(key => (
                <div key={key} className="form-field">
                    <label>{key}</label>
                    <input
                        type="text"
                        value={formData[key]}
                        readOnly={readOnly}
                        onChange={(e) => handleChange(key, e.target.value)}
                    />
                </div>
            ))}
        </form>
    );
};
