import React from "react";
import "./footer.scss";

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-logo">haudau.com</div>
                <div className="footer-copyright">
                    &copy; 2025 haudau.com.<br/> All rights reserved.
                </div>
            </div>
        </footer>
    );
}