
const getListTool = async () => {
  let results = [];
  await fetch(process.env.REACT_APP_URL + "data/listtool.json")
    .then(response => response.json())
    .then(data => results = data)
    .catch(error => {
      console.error('Error fetching JSON:', error);
    });
  return results;
}

export {getListTool};