import "./test.scss";

export const Test = () => {
    return (<div>
        <div class="card-body">
            <h5 class="mb-4">Simple Line Icons (189 icons)</h5>

            <div class="simple-line-icons">
                <div class="glyph">
                    <div class="glyph-icon simple-icon-user"></div>
                    <div class="class-name">simple-icon-user</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-people"></div>
                    <div class="class-name">simple-icon-people</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-user-female"></div>
                    <div class="class-name">simple-icon-user-female</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-user-follow"></div>
                    <div class="class-name">simple-icon-user-follow</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-user-following"></div>
                    <div class="class-name">simple-icon-user-following</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-user-unfollow"></div>
                    <div class="class-name">simple-icon-user-unfollow</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-login"></div>
                    <div class="class-name">simple-icon-login</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-logout"></div>
                    <div class="class-name">simple-icon-logout</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-emotsmile"></div>
                    <div class="class-name">simple-icon-emotsmile</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-phone"></div>
                    <div class="class-name">simple-icon-phone</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-call-end"></div>
                    <div class="class-name">simple-icon-call-end</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-call-in"></div>
                    <div class="class-name">simple-icon-call-in</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-call-out"></div>
                    <div class="class-name">simple-icon-call-out</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-map"></div>
                    <div class="class-name">simple-icon-map</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-location-pin"></div>
                    <div class="class-name">simple-icon-location-pin</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-direction"></div>
                    <div class="class-name">simple-icon-direction</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-directions"></div>
                    <div class="class-name">simple-icon-directions</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-compass"></div>
                    <div class="class-name">simple-icon-compass</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-layers"></div>
                    <div class="class-name">simple-icon-layers</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-menu"></div>
                    <div class="class-name">simple-icon-menu</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-list"></div>
                    <div class="class-name">simple-icon-list</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-options-vertical"></div>
                    <div class="class-name">simple-icon-options-vertical</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-options"></div>
                    <div class="class-name">simple-icon-options</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-arrow-down"></div>
                    <div class="class-name">simple-icon-arrow-down</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-arrow-left"></div>
                    <div class="class-name">simple-icon-arrow-left</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-arrow-right"></div>
                    <div class="class-name">simple-icon-arrow-right</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-arrow-up"></div>
                    <div class="class-name">simple-icon-arrow-up</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-arrow-up-circle"></div>
                    <div class="class-name">simple-icon-arrow-up-circle</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-arrow-left-circle"></div>
                    <div class="class-name">simple-icon-arrow-left-circle</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-arrow-right-circle"></div>
                    <div class="class-name">simple-icon-arrow-right-circle</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-arrow-down-circle"></div>
                    <div class="class-name">simple-icon-arrow-down-circle</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-check"></div>
                    <div class="class-name">simple-icon-check</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-clock"></div>
                    <div class="class-name">simple-icon-clock</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-plus"></div>
                    <div class="class-name">simple-icon-plus</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-minus"></div>
                    <div class="class-name">simple-icon-minus</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-close"></div>
                    <div class="class-name">simple-icon-close</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-event"></div>
                    <div class="class-name">simple-icon-event</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-exclamation"></div>
                    <div class="class-name">simple-icon-exclamation</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-organization"></div>
                    <div class="class-name">simple-icon-organization</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-trophy"></div>
                    <div class="class-name">simple-icon-trophy</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-screen-smartphone"></div>
                    <div class="class-name">simple-icon-screen-smartphone</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-screen-desktop"></div>
                    <div class="class-name">simple-icon-screen-desktop</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-plane"></div>
                    <div class="class-name">simple-icon-plane</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-notebook"></div>
                    <div class="class-name">simple-icon-notebook</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-mustache"></div>
                    <div class="class-name">simple-icon-mustache</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-mouse"></div>
                    <div class="class-name">simple-icon-mouse</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-magnet"></div>
                    <div class="class-name">simple-icon-magnet</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-energy"></div>
                    <div class="class-name">simple-icon-energy</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-disc"></div>
                    <div class="class-name">simple-icon-disc</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-cursor"></div>
                    <div class="class-name">simple-icon-cursor</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-cursor-move"></div>
                    <div class="class-name">simple-icon-cursor-move</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-crop"></div>
                    <div class="class-name">simple-icon-crop</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-chemistry"></div>
                    <div class="class-name">simple-icon-chemistry</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-speedometer"></div>
                    <div class="class-name">simple-icon-speedometer</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-shield"></div>
                    <div class="class-name">simple-icon-shield</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-screen-tablet"></div>
                    <div class="class-name">simple-icon-screen-tablet</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-magic-wand"></div>
                    <div class="class-name">simple-icon-magic-wand</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-hourglass"></div>
                    <div class="class-name">simple-icon-hourglass</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-graduation"></div>
                    <div class="class-name">simple-icon-graduation</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-ghost"></div>
                    <div class="class-name">simple-icon-ghost</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-game-controller"></div>
                    <div class="class-name">simple-icon-game-controller</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-fire"></div>
                    <div class="class-name">simple-icon-fire</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-eyeglass"></div>
                    <div class="class-name">simple-icon-eyeglass</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-envelope-open"></div>
                    <div class="class-name">simple-icon-envelope-open</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-envelope-letter"></div>
                    <div class="class-name">simple-icon-envelope-letter</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-bell"></div>
                    <div class="class-name">simple-icon-bell</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-badge"></div>
                    <div class="class-name">simple-icon-badge</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-anchor"></div>
                    <div class="class-name">simple-icon-anchor</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-wallet"></div>
                    <div class="class-name">simple-icon-wallet</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-vector"></div>
                    <div class="class-name">simple-icon-vector</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-speech"></div>
                    <div class="class-name">simple-icon-speech</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-puzzle"></div>
                    <div class="class-name">simple-icon-puzzle</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-printer"></div>
                    <div class="class-name">simple-icon-printer</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-present"></div>
                    <div class="class-name">simple-icon-present</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-playlist"></div>
                    <div class="class-name">simple-icon-playlist</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-pin"></div>
                    <div class="class-name">simple-icon-pin</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-picture"></div>
                    <div class="class-name">simple-icon-picture</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-handbag"></div>
                    <div class="class-name">simple-icon-handbag</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-globe-alt"></div>
                    <div class="class-name">simple-icon-globe-alt</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-globe"></div>
                    <div class="class-name">simple-icon-globe</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-folder-alt"></div>
                    <div class="class-name">simple-icon-folder-alt</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-folder"></div>
                    <div class="class-name">simple-icon-folder</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-film"></div>
                    <div class="class-name">simple-icon-film</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-feed"></div>
                    <div class="class-name">simple-icon-feed</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-drop"></div>
                    <div class="class-name">simple-icon-drop</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-drawer"></div>
                    <div class="class-name">simple-icon-drawer</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-docs"></div>
                    <div class="class-name">simple-icon-docs</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-doc"></div>
                    <div class="class-name">simple-icon-doc</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-diamond"></div>
                    <div class="class-name">simple-icon-diamond</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-cup"></div>
                    <div class="class-name">simple-icon-cup</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-calculator"></div>
                    <div class="class-name">simple-icon-calculator</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-bubbles"></div>
                    <div class="class-name">simple-icon-bubbles</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-briefcase"></div>
                    <div class="class-name">simple-icon-briefcase</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-book-open"></div>
                    <div class="class-name">simple-icon-book-open</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-basket-loaded"></div>
                    <div class="class-name">simple-icon-basket-loaded</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-basket"></div>
                    <div class="class-name">simple-icon-basket</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-bag"></div>
                    <div class="class-name">simple-icon-bag</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-action-undo"></div>
                    <div class="class-name">simple-icon-action-undo</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-action-redo"></div>
                    <div class="class-name">simple-icon-action-redo</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-wrench"></div>
                    <div class="class-name">simple-icon-wrench</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-umbrella"></div>
                    <div class="class-name">simple-icon-umbrella</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-trash"></div>
                    <div class="class-name">simple-icon-trash</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-tag"></div>
                    <div class="class-name">simple-icon-tag</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-support"></div>
                    <div class="class-name">simple-icon-support</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-frame"></div>
                    <div class="class-name">simple-icon-frame</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-size-fullscreen"></div>
                    <div class="class-name">simple-icon-size-fullscreen</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-size-actual"></div>
                    <div class="class-name">simple-icon-size-actual</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-shuffle"></div>
                    <div class="class-name">simple-icon-shuffle</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-share-alt"></div>
                    <div class="class-name">simple-icon-share-alt</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-share"></div>
                    <div class="class-name">simple-icon-share</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-rocket"></div>
                    <div class="class-name">simple-icon-rocket</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-question"></div>
                    <div class="class-name">simple-icon-question</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-pie-chart"></div>
                    <div class="class-name">simple-icon-pie-chart</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-pencil"></div>
                    <div class="class-name">simple-icon-pencil</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-note"></div>
                    <div class="class-name">simple-icon-note</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-loop"></div>
                    <div class="class-name">simple-icon-loop</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-home"></div>
                    <div class="class-name">simple-icon-home</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-grid"></div>
                    <div class="class-name">simple-icon-grid</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-graph"></div>
                    <div class="class-name">simple-icon-graph</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-microphone"></div>
                    <div class="class-name">simple-icon-microphone</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-music-tone-alt"></div>
                    <div class="class-name">simple-icon-music-tone-alt</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-music-tone"></div>
                    <div class="class-name">simple-icon-music-tone</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-earphones-alt"></div>
                    <div class="class-name">simple-icon-earphones-alt</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-earphones"></div>
                    <div class="class-name">simple-icon-earphones</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-equalizer"></div>
                    <div class="class-name">simple-icon-equalizer</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-like"></div>
                    <div class="class-name">simple-icon-like</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-dislike"></div>
                    <div class="class-name">simple-icon-dislike</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-control-start"></div>
                    <div class="class-name">simple-icon-control-start</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-control-rewind"></div>
                    <div class="class-name">simple-icon-control-rewind</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-control-play"></div>
                    <div class="class-name">simple-icon-control-play</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-control-pause"></div>
                    <div class="class-name">simple-icon-control-pause</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-control-forward"></div>
                    <div class="class-name">simple-icon-control-forward</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-control-end"></div>
                    <div class="class-name">simple-icon-control-end</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-volume-1"></div>
                    <div class="class-name">simple-icon-volume-1</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-volume-2"></div>
                    <div class="class-name">simple-icon-volume-2</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-volume-off"></div>
                    <div class="class-name">simple-icon-volume-off</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-calendar"></div>
                    <div class="class-name">simple-icon-calendar</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-bulb"></div>
                    <div class="class-name">simple-icon-bulb</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-chart"></div>
                    <div class="class-name">simple-icon-chart</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-ban"></div>
                    <div class="class-name">simple-icon-ban</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-bubble"></div>
                    <div class="class-name">simple-icon-bubble</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-camrecorder"></div>
                    <div class="class-name">simple-icon-camrecorder</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-camera"></div>
                    <div class="class-name">simple-icon-camera</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-cloud-download"></div>
                    <div class="class-name">simple-icon-cloud-download</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-cloud-upload"></div>
                    <div class="class-name">simple-icon-cloud-upload</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-envelope"></div>
                    <div class="class-name">simple-icon-envelope</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-eye"></div>
                    <div class="class-name">simple-icon-eye</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-flag"></div>
                    <div class="class-name">simple-icon-flag</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-heart"></div>
                    <div class="class-name">simple-icon-heart</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-info"></div>
                    <div class="class-name">simple-icon-info</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-key"></div>
                    <div class="class-name">simple-icon-key</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-link"></div>
                    <div class="class-name">simple-icon-link</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-lock"></div>
                    <div class="class-name">simple-icon-lock</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-lock-open"></div>
                    <div class="class-name">simple-icon-lock-open</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-magnifier"></div>
                    <div class="class-name">simple-icon-magnifier</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-magnifier-add"></div>
                    <div class="class-name">simple-icon-magnifier-add</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-magnifier-remove"></div>
                    <div class="class-name">simple-icon-magnifier-remove</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-paper-clip"></div>
                    <div class="class-name">simple-icon-paper-clip</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-paper-plane"></div>
                    <div class="class-name">simple-icon-paper-plane</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-power"></div>
                    <div class="class-name">simple-icon-power</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-refresh"></div>
                    <div class="class-name">simple-icon-refresh</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-reload"></div>
                    <div class="class-name">simple-icon-reload</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-settings"></div>
                    <div class="class-name">simple-icon-settings</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-star"></div>
                    <div class="class-name">simple-icon-star</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-symbol-female"></div>
                    <div class="class-name">simple-icon-symbol-female</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-symbol-male"></div>
                    <div class="class-name">simple-icon-symbol-male</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-target"></div>
                    <div class="class-name">simple-icon-target</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-credit-card"></div>
                    <div class="class-name">simple-icon-credit-card</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-paypal"></div>
                    <div class="class-name">simple-icon-paypal</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-tumblr"></div>
                    <div class="class-name">simple-icon-social-tumblr</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-twitter"></div>
                    <div class="class-name">simple-icon-social-twitter</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-facebook"></div>
                    <div class="class-name">simple-icon-social-facebook</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-instagram"></div>
                    <div class="class-name">simple-icon-social-instagram</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-linkedin"></div>
                    <div class="class-name">simple-icon-social-linkedin</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-pinterest"></div>
                    <div class="class-name">simple-icon-social-pinterest</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-github"></div>
                    <div class="class-name">simple-icon-social-github</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-google"></div>
                    <div class="class-name">simple-icon-social-google</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-reddit"></div>
                    <div class="class-name">simple-icon-social-reddit</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-skype"></div>
                    <div class="class-name">simple-icon-social-skype</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-dribbble"></div>
                    <div class="class-name">simple-icon-social-dribbble</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-behance"></div>
                    <div class="class-name">simple-icon-social-behance</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-foursqare"></div>
                    <div class="class-name">simple-icon-social-foursqare</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-soundcloud"></div>
                    <div class="class-name">simple-icon-social-soundcloud</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-spotify"></div>
                    <div class="class-name">simple-icon-social-spotify</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-stumbleupon"></div>
                    <div class="class-name">simple-icon-social-stumbleupon</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-youtube"></div>
                    <div class="class-name">simple-icon-social-youtube</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-dropbox"></div>
                    <div class="class-name">simple-icon-social-dropbox</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-vkontakte"></div>
                    <div class="class-name">simple-icon-social-vkontakte</div>
                </div>
                <div class="glyph">
                    <div class="glyph-icon simple-icon-social-steam"></div>
                    <div class="class-name">simple-icon-social-steam</div>
                </div>
            </div>
        </div>
    </div>)
}