import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../../component/icon";
import "./home.scss";
import * as Tools from "../../action/tools";


export const Home = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const getList = async () => {
            const listTool = await Tools.getListTool();
            setData(listTool);
        }
        getList();
    }, []);
    return (<div className="home-page">
        {data.map((toolgroup, i) => {
            return (
                <div key={i} className="group">
                    <div className={"header-group text text-" + toolgroup.color}>{toolgroup.groupname}</div>
                    <div className="products">
                        {toolgroup.toollist.map((tool, idx) => {
                            return (<div key={idx} className="product-item">
                                <Link to={"/tool/" + tool.code}>
                                    <div className="product-content">
                                        <div className={"icon icon-" + toolgroup.color}>
                                            <Icon is={tool.icon} />
                                        </div>
                                        <div className="content">
                                            <div className={"title text text-" + toolgroup.color}>
                                                <b>{tool.name}</b>
                                            </div>
                                            <div className="description">
                                                {tool.description}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>)
                        })
                        }
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div >);
        }
        )}
    </div>);

}