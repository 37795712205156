import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Header from "./component/header";
import Footer from "./component/footer";

import Home from "./page/home";
import Test from "./page/test";
import Tool from "./page/tool";
import "./style.scss";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <div className="main-container">
          <div className="main-content">
            <Routes>
              <Route path="/test" element={<Test />} />
              <Route path="/tool/:id" element={<Tool />} ></Route>
              <Route path="/" element={<Home />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
