
const removeDuplicate = async (inputString) => {
    const lines = inputString.split('\n');
    const uniqueLines = new Set();
    console.log(lines);
    for (const line of lines) {
        uniqueLines.add(line.replace("\r", ""));
    }
    return Array.from(uniqueLines).join('\n');
  }
  
const phoneNumberExtractor = (text) => {
    text = text.replace(/[.-]/g, "");
    const phonePattern = /((\+?84)|0)(3[2-9]|5[2689]|7[06-9]|8[1-9]|9[0-9])[0-9]{7}\b/g;
    return text.match(phonePattern)?.join('\n');
}

const emailExtractor = (text) => {
    const emailPattern  = /[\w\\.-]+@[\w\\.-]+\.\w+/g;
    return text.match(emailPattern )?.join('\n');
}

  export { removeDuplicate, phoneNumberExtractor, emailExtractor };