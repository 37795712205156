import { useParams } from "react-router-dom";
import "./tool.scss";
import 'animate.css';
import { useEffect, useState } from "react";
import * as  FormData from "../../action/form-data";
import * as  Setting from "../../action/setting";
import * as  Input from "../../action/input";
import * as  Output from "../../action/output";
import * as  RegexPattern from "../../action/regex-pattern";
import { Editor } from "@monaco-editor/react";
import Loading from "../../component/loading";
import * as StringUtils from "../../extension/StringUtils";
import Button from "../../component/button";
import Form from "../../component/form";
import { callApi } from "../../action/call-api";

export const Tool = () => {
    let { id } = useParams();
    const [formData, setFormData] = useState({});
    const [setting, setSetting] = useState({});
    const [input, setInput] = useState("");
    const [output, setOutput] = useState("");
    const [regexPattern, setRegexPattern] = useState([]);
    //loading
    const [loading, setLoading] = useState(true);
    const [isShowPattern, setIsShowPattern] = useState(false);
    const [dataCount, setDataCount] = useState({ daily_count: 0, total: 0 });

    useEffect(() => {
        const getData = async (id) => {
            setLoading(true);

            const formValue = await FormData.getFormData(id);
            setFormData(formValue);

            const settingValue = await Setting.getSetting(id);
            setSetting(settingValue);


            const inputValue = await Input.getInput(id);
            setInput(inputValue);

            const outputValue = await Output.getOutput(id);
            setOutput(outputValue);

            const regexPatternList = await RegexPattern.getRegexPattern(id);
            setRegexPattern(regexPatternList);
            setLoading(false);

            let data_count = await callApi("count/" + id);
            setDataCount(data_count);
        }
        getData(id);
    }, [id]);

    const handleFormChange = (updatedFormData) => {
        setFormData(updatedFormData);
    };

    const runAction = async () => {
        let result = input;
        regexPattern.forEach(pattern => {
            const newValue = formData[pattern.to.replace("userData.", "")] ? formData[pattern.to.replace("userData.", "")] : pattern.to;
            // const escapedPattern = pattern.from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regexPattern = new RegExp(pattern.from, "gm");
            result = result.replace(regexPattern, newValue);
        });
        result = await runScript(result);
        setOutput(result);
        setIsShowPattern(false);
    }

    const runScript = async (content) => {
        if (setting.api) {
            let data = JSON.stringify({ prompt: content, chat_id: id });
            let response = await callApi("chat", data, "POST");
            try {
                let response_data = response.response;
                return response_data;
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }

        switch (id) {
            case "duplicate-lines-remover":
                await StringUtils.removeDuplicate(content)
                    .then(data => {
                        content = data;
                        return data;
                    })
                    .catch(error => {
                        console.error('Error using string utils:', error);
                    });
                return content;
            case "phone-number-extractor":
                return StringUtils.phoneNumberExtractor(content);
            case "email-extractor":
                return StringUtils.emailExtractor(content);

            default:
                return content;
        }
    }

    const patternAction = () => {
        if (isShowPattern) {
            setIsShowPattern(false);
        } else {
            setIsShowPattern(true);
        }
    }

    return (loading ? <Loading /> : <div className="tool animate__animated animate__fadeIn">
        <div className="title-tool">
            {setting.name}
        </div>
        <Form
            id="form"
            labelMode={"floating"}
            formData={formData}
            readOnly={false}
            showColonAfterLabel={true}
            labelLocation={"left"}
            minColWidth={300}
            colCount={setting.column}
            onChange={handleFormChange}
        />
        <div className="buttons">
            <Button
                text="Execute"
                type="success"
                stylingMode="contained"
                onClick={runAction}
            />
            {setting.patternEditable ? <Button
                text={isShowPattern ? "Save" : "Modify Pattern"}
                type="normal"
                stylingMode="contained"
                onClick={patternAction}
            /> : <></>}
            <div className="data-count">
                Daily Count: {dataCount.daily_count}, Total: {dataCount.total}
            </div>
        </div>

        {isShowPattern ? <Editor
            className="animate__animated animate__fadeIn"
            height="65vh"
            language={setting.outputLanguage}
            value={JSON.stringify(regexPattern)}
            onChange={data => setRegexPattern(JSON.parse(data))}
            options={{ wordWrap: 'on' }}
        /> : <></>}

        {(!setting.useDiff && !isShowPattern) ? <Editor
            className="animate__animated animate__fadeIn"
            height="65vh"
            language={setting.outputLanguage}
            value={output}
            options={{ wordWrap: 'on' }}
        /> : <></>}
        {(setting.useDiff && !isShowPattern) ?
            <div className="editor-container">
                <Editor
                    className="editor animate__animated animate__fadeIn"
                    language={setting.inputLanguage}
                    value={input}
                    onChange={data => setInput(data)}
                    options={{ wordWrap: 'on' }}
                />
                <Editor
                    className="editor animate__animated animate__fadeIn"
                    language={setting.outputLanguage}
                    value={output}
                    options={{ wordWrap: 'on' }}
                />
            </div>
            : <></>}

    </div>)
}
