import React from 'react';

export const Button = ({ text, type, stylingMode, disabled, onClick }) => {
    return (
        <button
            className={`btn ${type} ${stylingMode}`}
            disabled={disabled}
            onClick={onClick}
            style={{
                padding: '12px 24px',
                borderRadius: '8px',
                border: 'none',
                background: 'linear-gradient(45deg, #43a047, #66bb6a)',
                color: '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                transition: 'background-color 0.3s ease, transform 0.3s ease',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.background = 'linear-gradient(45deg, #66bb6a, #43a047)';
                e.currentTarget.style.transform = 'scale(1.05)';
            }}
            onMouseOut={(e) => {
                e.currentTarget.style.background = 'linear-gradient(45deg, #43a047, #66bb6a)';
                e.currentTarget.style.transform = 'scale(1)';
            }}
        >
            {text}
        </button>
    );
};
