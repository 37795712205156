const callApi = async (endpoint, data = null, method = 'GET') => {
    const url = `${process.env.REACT_APP_PYTHON}${endpoint}`;
    try {
        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: data ? JSON.stringify(data) : null
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
};

module.exports = {
    callApi
};
