
const getOutput = async (code) => {
  let results = [];
  await fetch(process.env.REACT_APP_URL + "data/" + code + "/output.txt")
    .then(response => response.text())
    .then(data => results = data)
    .catch(error => {
      console.error('Error fetching Text:', error);
    });
  return results;
}

export { getOutput };